<template>
  <component :is="mediaLayout" class="default-layout">
    <router-view />
  </component>
</template>

<script>
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import { computed, defineAsyncComponent } from 'vue'

export default {
  name: 'DefaultLayout',
  components: {
    MobileLayout: defineAsyncComponent(() => import('@/layouts/MobileLayout')),
    DesktopLayout: defineAsyncComponent(() => import('@/layouts/DesktopLayout'))
  },
  setup () {
    const {
      isMobile,
      isDesktop
    } = useBreakpointsMedia()

    const layouts = {
      mobile: 'MobileLayout',
      desktop: 'DesktopLayout',
      tablet: 'DesktopLayout'
    }
    const mediaLayout = computed(() => {
      if (isMobile.value) {
        return layouts.mobile
      }
      if (isDesktop.value) {
        return layouts.desktop
      }
      return layouts.tablet
    })

    return {
      mediaLayout
    }
  }
}
</script>

<style lang="scss">
.default-layout {
  background: $mainBackgroundColor;
}
</style>
